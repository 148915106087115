import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

let category = window.location.pathname.slice(1);
// console.log("main.ts:category", category);
if (category.startsWith("maps")) { category = "maps"; }
let regionName = "";
if (category.startsWith("region")) {
  regionName = category.substring(7);
  const index = regionName.indexOf("%20");
  if (index !== -1) { regionName = regionName.substr(0, index) + " " + regionName.substr(index + 3); }
  // console.log("main.ts:regionName", regionName);
  category = "region";
}
let placeName = "";
if (category.startsWith("place")) {
  placeName = category.substring(6);
  const index = placeName.indexOf("%20");
  // console.log("main.ts:index", index);
  if (index !== -1) { placeName = placeName.substr(0, index) + " " + placeName.substr(index + 3); }
  // console.log("main.ts:placeName", placeName);
  category = "place";
}
if (!category) { category = "trips"; }
// console.log("main.ts:category2", category);

// meta-description
const snippets = {
  trips: "Travel planner - Free app for planning individual trips - travel tips included",
  examples: "Examples - Examples for travel planner Trip4You",
  maps: "Maps - Free outdoor app for hiking & biking - Route planning, Navigation, GPX-tracks, recording tours, search for toures, live tracking & more features",
  explore: "You can explore regions and places and find out what there is to see or do - the information will help you plan your trip.",
  region: "Travel information about " + regionName + " - You can explore the region and find out what there is to see or do. The information will help you plan your trip.",
  place: "Travel information about " + placeName + " - You can explore the place and find out what there is to see or do. The information will help you plan your trip.",
};
const snippet = snippets[category];
// console.log("main.ts:snippet", snippet);
const metaDesc = document.querySelector("meta[name='description']");
// console.log("main.ts:metaDesc", metaDesc);
metaDesc.setAttribute("content", snippet);
// console.log("main.ts:end");
