import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RockItCommandSenderService } from "../shell/rockit-command-sender.service";

import { Container, Duty, EnumGlobalStatusCode, Event, Image, Info, Link, Notice, Place, Resto, Stay, Transfer, Trip, TripShare, XDoc } from "../../dto.generated/api";

import {
  ApplyTripShareKeyCommand, ApplyTripShareKeyCommandResult, DoRepairItemsXCommand, DoRepairItemsXCommandResult, GetDemoTripCommand,
  GetDemoTripCommandResult, GetDemoTripsCommand, GetDemoTripsCommandResult, GetLoggedInUserCommand, GetLoggedInUserCommandResult,
  GetSharedTripCommand, GetSharedTripCommandResult, GetTripCommand, GetTripCommandResult, GetTripsCommand, GetTripsCommandResult,
  GetTripSharesForTripCommand, GetTripSharesForTripCommandResult
} from "../../dto.generated/api";

import {
  AddContainerCommand, AddContainerCommandResult, AddDutyCommand, AddDutyCommandResult, AddEventCommand, AddEventCommandResult,
  AddImageCommand, AddImageCommandResult, AddInfoCommand, AddInfoCommandResult, AddLinkCommand, AddLinkCommandResult, AddNoticeCommand,
  AddNoticeCommandResult, AddPlaceCommand, AddPlaceCommandResult, AddRestoCommand, AddRestoCommandResult, AddStayCommand,
  AddStayCommandResult, AddTransferCommand, AddTransferCommandResult, AddTripCommand, AddTripCommandResult, AddTripShareCommand,
  AddTripShareCommandResult, AddXDocCommand, AddXDocCommandResult
} from "../../dto.generated/api";

import {
  UpdateContainerCommand, UpdateContainerCommandResult, UpdateDutyCommand, UpdateDutyCommandResult, UpdateEventCommand,
  UpdateEventCommandResult, UpdateImageCommand, UpdateImageCommandResult, UpdateInfoCommand, UpdateInfoCommandResult, UpdateLinkCommand,
  UpdateLinkCommandResult, UpdateNoticeCommand, UpdateNoticeCommandResult, UpdatePlaceCommand, UpdatePlaceCommandResult,
  UpdateRestoCommand, UpdateRestoCommandResult, UpdateStayCommand, UpdateStayCommandResult, UpdateTransferCommand,
  UpdateTransferCommandResult, UpdateTripCommand, UpdateTripCommandResult, UpdateTripShareCommand, UpdateTripShareCommandResult,
  UpdateXDocCommand, UpdateXDocCommandResult
} from "../../dto.generated/api";

import {
  CopyTripCommand, CopyTripCommandResult, DeleteContainerCommand, DeleteContainerCommandResult, DeleteDutyCommand,
  DeleteDutyCommandResult, DeleteEventCommand, DeleteEventCommandResult, DeleteImageCommand, DeleteImageCommandResult, DeleteInfoCommand,
  DeleteInfoCommandResult, DeleteLinkCommand, DeleteLinkCommandResult, DeleteNoticeCommand, DeleteNoticeCommandResult, DeletePlaceCommand,
  DeletePlaceCommandResult, DeleteRestoCommand, DeleteRestoCommandResult, DeleteStayCommand, DeleteStayCommandResult, DeleteTransferCommand,
  DeleteTransferCommandResult, DeleteTripCommand, DeleteTripCommandResult, DeleteTripShareCommand, DeleteTripShareCommandResult,
  DeleteXDocCommand, DeleteXDocCommandResult
} from "../../dto.generated/api";

import {
  SaveAsRefEventCommand, SaveAsRefEventCommandResult,
  SaveAsRefInfoCommand, SaveAsRefInfoCommandResult,
  SaveAsRefLinkCommand, SaveAsRefLinkCommandResult,
  SaveAsRefPlaceCommand, SaveAsRefPlaceCommandResult,
  SaveAsRefRestoCommand, SaveAsRefRestoCommandResult,
  SaveAsRefStayCommand, SaveAsRefStayCommandResult,
  SaveAsRefTransferCommand, SaveAsRefTransferCommandResult,
} from "../../dto.generated/api";

import { cloneContainer, getMinLocalContainerIdForTrip } from "../utils/utils-container";
import { cloneDuty, getMinLocalDutyIdForTrip } from "../utils/utils-duty";
import { cloneEvent, getMinLocalEventIdForTrip } from "../utils/utils-event";
import { cloneInfo, getMinLocalInfoIdForTrip } from "../utils/utils-info";
import { cloneLink, getMinLocalLinkIdForTrip } from "../utils/utils-link";
import { cloneNotice, getMinLocalNoticeIdForTrip } from "../utils/utils-notice";
import { clonePlace, getMinLocalPlaceIdForTrip } from "../utils/utils-place";
import { cloneResto, getMinLocalRestoIdForTrip } from "../utils/utils-resto";
import { cloneStay, getMinLocalStayIdForTrip } from "../utils/utils-stay";
import { cloneTransfer, getMinLocalTransferIdForTrip } from "../utils/utils-transfer";
import { cloneTrip } from "../utils/utils-trip";

@Injectable()
export class TripService {

  private workLocal: boolean;
  private currentLocalTrip: Trip;

  constructor(
    private commandSenderService: RockItCommandSenderService,
    private http: HttpClient) { }

  // user
  public async getLoggedInUser() {
    // console.log("GetLoggedInUser");
    const result = await this.commandSenderService.sendCommand<GetLoggedInUserCommandResult>(GetLoggedInUserCommand.create({}));
    return result.user;
  }

  // trip
  public async getTrips(loginCode: string) {
    console.log("GetTrips");
    const result = await this.commandSenderService.sendCommand<GetTripsCommandResult>(GetTripsCommand.create({}));
    // console.log("GetTrips-trips", result);
    return result.trips;
  }

  public async getDemoTrips() {
    console.log("GetDemoTrips");
    const result = await this.commandSenderService.sendCommand<GetDemoTripsCommandResult>(GetDemoTripsCommand.create({}));
    // console.log("GetDemoTrips-trips", result);
    return result.trips;
  }

  public async getTrip(tripId: number) {
    console.log("GetTrip-tripId", tripId);
    const result = await this.commandSenderService.sendCommand<GetTripCommandResult>(GetTripCommand.create({ tripId }));
    return result.trip;
  }

  public async getSharedTrip(tripId: number) {
    console.log("GetSharedTrip-tripId", tripId);
    const result = await this.commandSenderService.sendCommand<GetSharedTripCommandResult>(GetSharedTripCommand.create({ tripId }));
    return result.trip;
  }

  public async getDemoTrip(tripId: number) {
    console.log("GetDemoTrip-tripId", tripId);
    const result = await this.commandSenderService.sendCommand<GetDemoTripCommandResult>(GetDemoTripCommand.create({ tripId }));
    return result.trip;
  }

  public async addTrip(trip: Trip) {
    console.log("AddTrip-Trip:", trip);
    trip.id = 0;
    const result = await this.commandSenderService.sendCommand<AddTripCommandResult>(AddTripCommand.create({ newTrip: trip }));
    return result.addedTrip;
  }

  public async doRepairStructureOfTrip(tripId: number) {
    console.log("doRepairStructureOfTrip-tripId:", tripId);

    const result = await this.commandSenderService.sendCommand<DoRepairItemsXCommandResult>(
      DoRepairItemsXCommand.create({ tripId }));

    return result.success;
  }

  public async updateTrip(trip: Trip) {
    console.log("UpdateTrip-Trip:", trip);
    if (this.workLocal) {
      // this.storeLocalTrip(trip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateTripCommandResult>(UpdateTripCommand.create({ updateTrip: trip }));
    return result.statusCode;
  }

  public async deleteTrip(tripId: number) {
    console.log("DeleteTrip-tripId:", tripId);
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteTripCommandResult>(DeleteTripCommand.create({ tripId }));
    return result.statusCode;
  }
  public async copyTrip(tripId: number) {
    console.log("CopyTrip-tripId:", tripId);
    const result = await this.commandSenderService.sendCommand<CopyTripCommandResult>(CopyTripCommand.create({ tripId }));
    return result.trip;
  }

  // trip-share
  public async getTripSharesForTrip(tripId: number) {
    console.log("GetTripShareForTrip-tripId:", tripId);
    const result = await this.commandSenderService.sendCommand<GetTripSharesForTripCommandResult>
      (GetTripSharesForTripCommand.create({ tripId }));
    return result.tripShares;
  }
  public async addTripShare(tripShare: TripShare) {
    console.log("AddTripShare-TripShare:", tripShare);
    const result = await this.commandSenderService.sendCommand<AddTripShareCommandResult>
      (AddTripShareCommand.create({ newTripShare: tripShare }));
    return result.addedTripShare;
  }
  public async updateTripShare(tripShare: TripShare) {
    console.log("UpdateTripShare-TripShare:", tripShare);
    const result = await this.commandSenderService.sendCommand<UpdateTripShareCommandResult>
      (UpdateTripShareCommand.create({ updateTripShare: tripShare }));
    return result.statusCode;
  }
  public async deleteTripShare(tripshareId: number) {
    console.log("DeleteTripShare-tripshareId:", tripshareId);
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteTripShareCommandResult>(
      DeleteTripShareCommand.create({ tripShareId: tripshareId }));
    return result.statusCode;
  }
  public async applyTripShareKey(key: string) {
    console.log("ApplyTripShareKey-key:", key);
    const result = await this.commandSenderService.sendCommand<ApplyTripShareKeyCommandResult>
      (ApplyTripShareKeyCommand.create({ shareKey: key }));
    return result;
  }

  // container
  public async addContainer(container: Container) {
    console.log("AddContainer-container:", container);
    if (this.workLocal) { return container; }
    container.id = 0;
    const result = await this.commandSenderService.sendCommand<AddContainerCommandResult>(
      AddContainerCommand.create({ newContainer: container }));
    return result.addedContainer;
  }
  public async updateContainer(container: Container) {
    console.log("UpdateContainer-container:", container);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateContainerCommandResult>
      (UpdateContainerCommand.create({ updateContainer: container }));
    return result.statusCode;
  }
  public async deleteContainer(containerId: number) {
    console.log("DeleteContainer-containerId:", containerId);
    if (this.workLocal) { return EnumGlobalStatusCode.Success; }
    const result = await this.commandSenderService.sendCommand<DeleteContainerCommandResult>(
      DeleteContainerCommand.create({ containerId }));
    return result.statusCode;
  }

  // duty
  public async addDuty(duty: Duty) {
    console.log("AddDuty-Duty:", duty);
    if (this.workLocal) { return duty; }
    duty.id = 0;
    const result = await this.commandSenderService.sendCommand<AddDutyCommandResult>(AddDutyCommand.create({ newDuty: duty }));
    return result.addedDuty;
  }
  public async deleteDuty(dutyId: number) {
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteDutyCommandResult>(DeleteDutyCommand.create({ dutyId }));
    return result.statusCode;
  }
  public async updateDuty(duty: Duty) {
    console.log("UpdateDuty-Duty:", duty);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateDutyCommandResult>(UpdateDutyCommand.create({ updateDuty: duty }));
    return result.statusCode;
  }

  // event
  public async addEvent(event: Event) {
    console.log("AddEvent-Event:", event);
    if (this.workLocal) { return event; }
    event.id = 0;
    const result = await this.commandSenderService.sendCommand<AddEventCommandResult>(AddEventCommand.create({ newEvent: event }));
    return result.addedEvent;
  }
  public async deleteEvent(eventId: number) {
    console.log("DeleteEvent-eventId:", eventId);
    if (this.workLocal) { return EnumGlobalStatusCode.Success; }
    const result = await this.commandSenderService.sendCommand<DeleteEventCommandResult>(DeleteEventCommand.create({ eventId }));
    return result.statusCode;
  }
  public async updateEvent(event: Event) {
    console.log("UpdateEvent-Event:", event);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateEventCommandResult>(UpdateEventCommand.create({ updateEvent: event }));
    return result.statusCode;
  }
  public async saveAsRefContainerEvent(event: Event, refContainerId: number) {
    console.log("SaveAsRefContainerEvent-Event:", event);
    const refPlaceId = 0;
    const refRegionId = 0;
    const refTransferId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefEventCommandResult>
      (SaveAsRefEventCommand.create({ saveAsRefEvent: event, refContainerId, refPlaceId, refRegionId, refTransferId }));
    console.log("SaveAsRefContainerEvent-result:", result);
    return result;
  }
  public async saveAsRefPlaceEvent(event: Event, refPlaceId: number) {
    console.log("SaveAsRefPlaceEvent-Event:", event);
    const refContainerId = 0;
    const refRegionId = 0;
    const refTransferId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefEventCommandResult>
      (SaveAsRefEventCommand.create({ saveAsRefEvent: event, refContainerId, refPlaceId, refRegionId, refTransferId }));
    console.log("SaveAsRefPlaceEvent-result:", result);
    return result;
  }
  public async saveAsRefRegionEvent(event: Event, refRegionId: number) {
    console.log("SaveAsRefRegionEvent-Event:", event);
    const refContainerId = 0;
    const refPlaceId = 0;
    const refTransferId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefEventCommandResult>
      (SaveAsRefEventCommand.create({ saveAsRefEvent: event, refContainerId, refPlaceId, refRegionId, refTransferId }));
    console.log("SaveAsRefRegionEvent-result:", result);
    return result;
  }
  public async saveAsRefTransferEvent(event: Event, refTransferId: number) {
    console.log("SaveAsRefTransferEvent-Event:", event);
    const refContainerId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefEventCommandResult>
      (SaveAsRefEventCommand.create({ saveAsRefEvent: event, refContainerId, refPlaceId, refRegionId, refTransferId }));
    console.log("SaveAsRefTransferEvent-result:", result);
    return result;
  }

  // image
  public async addImage(image: Image) {
    console.log("AddImage-Image:", image);
    if (this.workLocal) { return null; }
    image.id = 0;
    const result = await this.commandSenderService.sendCommand<AddImageCommandResult>(AddImageCommand.create({ newImage: image }));
    return result.addedImage;
  }
  public async deleteImage(imageId: number) {
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    // console.log("DeleteImage-imageId:", imageId);
    const result = await this.commandSenderService.sendCommand<DeleteImageCommandResult>(DeleteImageCommand.create({ imageId }));
    return result.statusCode;
  }
  public async updateImage(image: Image) {
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    console.log("UpdateImage-Image:", image);
    const result = await this.commandSenderService.sendCommand<UpdateImageCommandResult>(UpdateImageCommand.create({ updateImage: image }));
    return result.statusCode;
  }

  // info
  public async addInfo(info: Info) {
    console.log("AddInfo-Info:", info);
    if (this.workLocal) { return info; }
    info.id = 0;
    const result = await this.commandSenderService.sendCommand<AddInfoCommandResult>(AddInfoCommand.create({ newInfo: info }));
    return result.addedInfo;
  }
  public async deleteInfo(infoId: number) {
    // console.log("DeleteInfo-infoId:", infoId);
    const result = await this.commandSenderService.sendCommand<DeleteInfoCommandResult>(DeleteInfoCommand.create({ infoId }));
    return result.statusCode;
  }
  public async updateInfo(info: Info) {
    console.log("UpdateInfo-Info:", info);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateInfoCommandResult>(UpdateInfoCommand.create({ updateInfo: info }));
    return result.statusCode;
  }
  public async saveAsRefContainerInfo(info: Info, refContainerId: number) {
    console.log("saveAsRefContainerInfo-info:", info);
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefContainerInfo-result:", result);
    return result;
  }
  public async saveAsRefEventInfo(info: Info, refEventId: number) {
    console.log("saveAsRefEventInfo-info:", info);
    const refContainerId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefEventInfo-result:", result);
    return result;
  }
  public async saveAsRefPlaceInfo(info: Info, refPlaceId: number) {
    console.log("SaveAsRefPlaceInfo-info:", info);
    const refContainerId = 0;
    const refEventId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("SaveAsRefPlaceInfo-result:", result);
    return result;
  }
  public async saveAsRefRegionInfo(info: Info, refRegionId: number) {
    console.log("saveAsRefRegionInfo-info:", info);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefRegionInfo-result:", result);
    return result;
  }
  public async saveAsRefRestoInfo(info: Info, refRestoId: number) {
    console.log("saveAsRefRestoInfo-info:", info);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefRestoInfo-result:", result);
    return result;
  }
  public async saveAsRefStayInfo(info: Info, refStayId: number) {
    console.log("saveAsRefStayInfo-info:", info);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefInfoCommandResult>
      (SaveAsRefInfoCommand.create({ saveAsRefInfo: info, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefStayInfo-result:", result);
    return result;
  }

  // link
  public async addLink(link: Link) {
    console.log("AddLink-Link:", link);
    if (this.workLocal) { return link; }
    link.id = 0;
    const result = await this.commandSenderService.sendCommand<AddLinkCommandResult>(AddLinkCommand.create({ newLink: link }));
    return result.addedLink;
  }
  public async deleteLink(linkId: number) {
    console.log("DeleteLink-linkId:", linkId);
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteLinkCommandResult>(DeleteLinkCommand.create({ linkId }));
    return result.statusCode;
  }
  public async updateLink(link: Link) {
    console.log("UpdateLink-Link:", link);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateLinkCommandResult>(UpdateLinkCommand.create({ updateLink: link }));
    return result.statusCode;
  }
  public async saveAsRefContainerLink(link: Link, refContainerId: number) {
    console.log("saveAsRefContainerLink-link:", link);
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefContainerLink-result:", result);
    return result;
  }
  public async saveAsRefEventLink(link: Link, refEventId: number) {
    console.log("saveAsRefEventlink-link:", link);
    const refContainerId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefContainerLink-result:", result);
    return result;
  }
  public async saveAsRefPlaceLink(link: Link, refPlaceId: number) {
    console.log("saveAsRefPlacelink-link:", link);
    const refContainerId = 0;
    const refEventId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("SaveAsRefPlaceLink-result:", result);
    return result;
  }
  public async saveAsRefRegionLink(link: Link, refRegionId: number) {
    console.log("saveAsRefRegionLink-link:", link);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRestoId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefRegionLink-result:", result);
    return result;
  }
  public async saveAsRefRestoLink(link: Link, refRestoId: number) {
    console.log("saveAsRefRestoLink-link:", link);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refStayId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefRestoLink-result:", result);
    return result;
  }
  public async saveAsRefStayLink(link: Link, refStayId: number) {
    console.log("saveAsRefStayLink-link:", link);
    const refContainerId = 0;
    const refEventId = 0;
    const refPlaceId = 0;
    const refRegionId = 0;
    const refRestoId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefLinkCommandResult>
      (SaveAsRefLinkCommand.create({ saveAsRefLink: link, refContainerId, refEventId, refPlaceId, refRegionId, refRestoId, refStayId }));
    console.log("saveAsRefStayLink-result:", result);
    return result;
  }

  // notice
  public async addNotice(notice: Notice) {
    console.log("AddNotice-Notice:", notice);
    if (this.workLocal) { return notice; }
    notice.id = 0;
    const result = await this.commandSenderService.sendCommand<AddNoticeCommandResult>(AddNoticeCommand.create({ newNotice: notice }));
    return result.addedNotice;
  }
  public async deleteNotice(noticeId: number) {
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteNoticeCommandResult>
      (DeleteNoticeCommand.create({ noticeId }));
    return result.statusCode;
  }
  public async updateNotice(notice: Notice) {
    console.log("UpdateNotice-Notice:", notice);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateNoticeCommandResult>
      (UpdateNoticeCommand.create({ updateNotice: notice }));
    return result.statusCode;
  }

  // place
  public async addPlace(place: Place) {
    console.log("AddPlace-Place:", place);
    if (this.workLocal) { return place; }
    place.id = 0;
    const result = await this.commandSenderService.sendCommand<AddPlaceCommandResult>(AddPlaceCommand.create({ newPlace: place }));
    return result.addedPlace;
  }
  public async deletePlace(placeId: number) {
    console.log("DeletePlace-placeId:", placeId);
    if (this.workLocal) { return EnumGlobalStatusCode.Success; }
    const result = await this.commandSenderService.sendCommand<DeletePlaceCommandResult>(DeletePlaceCommand.create({ placeId }));
    return result.statusCode;
  }
  public async updatePlace(place: Place) {
    console.log("UpdatePlace-Place:", place);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdatePlaceCommandResult>(UpdatePlaceCommand.create({ updatePlace: place }));
    return result.statusCode;
  }

  public async saveAsRefContainerPlace(place: Place, refContainerId: number) {
    const refPlaceId = 0;
    const refRegionId = 0;
    console.log("SaveAsRefContainerPlace-Place:", place);
    const result = await this.commandSenderService.sendCommand<SaveAsRefPlaceCommandResult>
      (SaveAsRefPlaceCommand.create({ saveAsRefPlace: place, refContainerId, refPlaceId, refRegionId }));
    console.log("SaveAsRefContainerPlace-result:", result);
    return result;
  }
  public async saveAsRefPlacePlace(place: Place, refPlaceId: number) {
    const refContainerId = 0;
    const refRegionId = 0;
    console.log("SaveAsRefRegionPlace-Place:", place);
    const result = await this.commandSenderService.sendCommand<SaveAsRefPlaceCommandResult>
      (SaveAsRefPlaceCommand.create({ saveAsRefPlace: place, refContainerId, refPlaceId, refRegionId }));
    console.log("SaveAsRefRegionPlace-result:", result);
    return result;
  }
  public async saveAsRefRegionPlace(place: Place, refRegionId: number) {
    console.log("SaveAsRefRegionPlace-Place:", place);
    const refContainerId = 0;
    const refPlaceId = 0;
    const result = await this.commandSenderService.sendCommand<SaveAsRefPlaceCommandResult>
      (SaveAsRefPlaceCommand.create({ saveAsRefPlace: place, refContainerId, refPlaceId, refRegionId }));
    console.log("SaveAsRefRegionPlace-result:", result);
    return result;
  }

  // transfer
  public async addTransfer(transfer: Transfer) {
    console.log("AddTransfer-Transfer:", transfer);
    if (this.workLocal) { return transfer; }
    transfer.id = 0;
    const result = await this.commandSenderService.sendCommand<AddTransferCommandResult>(
      AddTransferCommand.create({ newTransfer: transfer }));
    return result.addedTransfer;
  }
  public async deleteTransfer(transferId: number) {
    console.log("DeleteTransfer-transferId:", transferId);
    if (this.workLocal) { return EnumGlobalStatusCode.Success; }
    const result = await this.commandSenderService.sendCommand<DeleteTransferCommandResult>(
      DeleteTransferCommand.create({ transferId }));
    return result.statusCode;
  }
  public async updateTransfer(transfer: Transfer) {
    console.log("UpdateTransfer-Transfer:", transfer);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateTransferCommandResult>
      (UpdateTransferCommand.create({ updateTransfer: transfer }));
    return result.statusCode;
  }
  public async saveAsRefTransfer(transfer: Transfer) {
    console.log("SaveAsRefTransfer-Transfer:", transfer);
    const result = await this.commandSenderService.sendCommand<SaveAsRefTransferCommandResult>
      (SaveAsRefTransferCommand.create({ saveAsRefTransfer: transfer }));
    console.log("SaveAsRefTransfer-result:", result);
    return result.statusCode;
  }

  // resto
  public async addResto(resto: Resto) {
    console.log("AddResto-Resto:", resto);
    if (this.workLocal) { return resto; }
    resto.id = 0;
    const result = await this.commandSenderService.sendCommand<AddRestoCommandResult>(AddRestoCommand.create({ newResto: resto }));
    return result.addedResto;
  }
  public async deleteResto(restoId: number) {
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteRestoCommandResult>(DeleteRestoCommand.create({ restoId }));
    return result.statusCode;
  }
  public async updateResto(resto: Resto) {
    console.log("UpdateResto-Resto:", resto);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateRestoCommandResult>(UpdateRestoCommand.create({ updateResto: resto }));
    return result.statusCode;
  }
  public async saveAsRefPlaceResto(resto: Resto, refPlaceId: number) {
    console.log("SaveAsRefPlaceResto-Resto:", resto);
    const result = await this.commandSenderService.sendCommand<SaveAsRefRestoCommandResult>
      (SaveAsRefRestoCommand.create({ saveAsRefResto: resto, refPlaceId }));
    console.log("SaveAsRefPlaceResto-result:", result);
    return result;
  }

  // stay
  public async addStay(stay: Stay) {
    console.log("AddStay-Stay:", stay);
    if (this.workLocal) { return stay; }
    stay.id = 0;
    const result = await this.commandSenderService.sendCommand<AddStayCommandResult>(AddStayCommand.create({ newStay: stay }));
    return result.addedStay;
  }
  public async deleteStay(stayId: number) {
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteStayCommandResult>(DeleteStayCommand.create({ stayId }));
    return result.statusCode;
  }
  public async updateStay(stay: Stay) {
    console.log("UpdateStay-Stay:", stay);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateStayCommandResult>(UpdateStayCommand.create({ updateStay: stay }));
    return result.statusCode;
  }
  public async saveAsRefPlaceStay(stay: Stay, refPlaceId: number) {
    console.log("SaveAsRefPlaceStay-Stay:", stay);
    const result = await this.commandSenderService.sendCommand<SaveAsRefStayCommandResult>
      (SaveAsRefStayCommand.create({ saveAsRefStay: stay, refPlaceId }));
    console.log("SaveAsRefPlaceStay-result:", result);
    return result;
  }

  // xdoc
  public async addXDoc(xdoc: XDoc) {
    if (this.workLocal) { return null; }
    console.log("AddXDoc-XDoc:", xdoc);
    xdoc.id = 0;
    const result = await this.commandSenderService.sendCommand<AddXDocCommandResult>(AddXDocCommand.create({ newXDoc: xdoc }));
    return result.addedXDoc;
  }
  public async deleteXDoc(xdocId: number) {
    // console.log("DeleteXDoc-xdocId:", xdocId);
    if (this.workLocal) { return EnumGlobalStatusCode.AccessNotPermited; }
    const result = await this.commandSenderService.sendCommand<DeleteXDocCommandResult>(DeleteXDocCommand.create({ xDocId: xdocId }));
    return result.statusCode;
  }
  public async updateXDoc(xdoc: XDoc) {
    console.log("UpdateXDoc-XDoc:", xdoc);
    if (this.workLocal) {
      // this.storeLocalTrip(this.currentLocalTrip);
      return EnumGlobalStatusCode.Success;
    }
    const result = await this.commandSenderService.sendCommand<UpdateXDocCommandResult>(UpdateXDocCommand.create({ updateXDoc: xdoc }));
    return result.statusCode;
  }



  // local-trips functions
  // set Work-local
  public setWorkLocal(workLocal: boolean) {
    console.log("setWorkLocal-workLocal", workLocal);
    this.workLocal = workLocal;
  }
  public getWorkLocal() {
    console.log("getWorkLocal-workLocal", this.workLocal);
    return (this.workLocal);
  }

  public getLocalTrips() {
    console.log("getLocalTrips");
    const itemName = "t4y-trips-trips-local";
    const localeStorage = window.localStorage;
    const itemJSON = localStorage.getItem(itemName);
    let trips = JSON.parse(itemJSON);
    if (!trips) { trips = new Array<Trip>(); }
    // console.log("trip-locale:getTrips-trips", trips);
    return trips;
  }

  public storeLocalTrips(trips: Array<Trip>) {
    console.log("storeLocalTrips-trips", trips);
    const itemName = "t4y-trips-trips-local";
    const itemJSON = JSON.stringify(trips);
    const localeStorage = window.localStorage;
    localStorage.setItem(itemName, itemJSON);
  }

  // trip-local
  public getLocalTrip(tripId: number) {
    console.log("getLocalTrip-tripId", tripId);
    const itemName = "t4y-trips-trip" + tripId;
    const localeStorage = window.localStorage;
    const itemJSON = localStorage.getItem(itemName);
    const trip = JSON.parse(itemJSON);
    // this.convertAllDatesInTrip(trip);
    return trip;
  }
  public storeLocalTrip(trip: Trip) {
    // console.log("storeLocalTrip", trip);
    // console.log("storeLocalTrip-workLocal", this.workLocal);
    if (!this.workLocal) { return; }
    const itemName = "t4y-trips-trip" + trip.id;
    const tripJSON = JSON.stringify(trip);
    const localeStorage = window.localStorage;
    localStorage.setItem(itemName, tripJSON);
  }
  public removeLocalTrip(trip: Trip) {
    // console.log("removeLocalTrip", trip);
    // console.log("removeLocalTrip-workLocal", this.workLocal);
    if (!this.workLocal) { return; }
    const itemName = "t4y-trips-trip" + trip.id;
    const tripJSON = JSON.stringify(trip);
    const localeStorage = window.localStorage;
    localStorage.removeItem(itemName);
  }
  public async syncLocalTrip(trip: Trip) {
    console.log("syncLocalTrip", trip);
    if (!this.workLocal) { return; }
    const syncedTrip = await this.syncTripWithCloud(trip);
    return syncedTrip;
  }

  public setCurrentLocalTrip(trip: Trip) {
    console.log("setCurrentLocalTrip:setCurrentTrip-trip:", trip);
    this.currentLocalTrip = trip;
  }

  public getNewLocalContainerId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalContainerIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalDutyId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalDutyIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalEventId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalEventIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalInfoId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalInfoIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalLinkId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalLinkIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalNoticeId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalNoticeIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalPlaceId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalPlaceIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalRestoId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalRestoIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalStayId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalStayIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }
  public getNewLocalTransferId() {
    if (!this.workLocal) { return 0; }
    let id = getMinLocalTransferIdForTrip(this.currentLocalTrip);
    id--;
    return id;
  }


  private async syncTripWithCloud(trip: Trip) {
    // temp no work local
    this.workLocal = false;
    // create new trip
    const cTrip = cloneTrip(trip);
    if (trip.id < 0) {
      const newTrip = await this.addTrip(cTrip);
      if (!newTrip) { return null; }
      cTrip.id = newTrip.id;
    }
    if (trip.id > 0) {
      const result = await this.updateTrip(cTrip);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync events
    for (const xEvent of trip.events) {
      // console.log("syncTripWithCloud-xEvent", xEvent);
      xEvent.tripId = cTrip.id;
      await this.syncEventWithCloud(xEvent);
    }
    // sync infos
    for (const xInfo of trip.infos) {
      xInfo.tripId = cTrip.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of trip.links) {
      xLink.tripId = cTrip.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync places
    for (const xPlace of trip.places) {
      // console.log("syncTripWithCloud-xPlace", xPlace);
      xPlace.tripId = cTrip.id;
      await this.syncPlaceWithCloud(xPlace);
    }
    // sync restos
    for (const xResto of trip.restos) {
      xResto.tripId = cTrip.id;
      await this.syncRestoWithCloud(xResto);
    }
    // sync stays
    for (const xStay of trip.stays) {
      xStay.tripId = cTrip.id;
      await this.syncStayWithCloud(xStay);
    }
    // sync transfers
    for (const xTransfer of trip.transfers) {
      xTransfer.tripId = cTrip.id;
      await this.syncTransferWithCloud(xTransfer);
    }

    // work again local
    this.workLocal = true;
    const syncTrip = await this.getTrip(cTrip.id);
    return syncTrip;
  }
  private async syncContainerWithCloud(container: Container) {
    // create new container
    const cContainer = cloneContainer(container);
    if (container.id < 0) {
      const newContainer = await this.addContainer(cContainer);
      if (!newContainer) { return null; }
      cContainer.id = newContainer.id;
    }
    if (container.id > 0) {
      const result = await this.updateContainer(cContainer);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync containers
    for (const xContainer of container.containers) {
      xContainer.containerId = cContainer.id;
      await this.syncContainerWithCloud(xContainer);
    }
    // sync duties
    for (const xDuty of container.duties) {
      xDuty.containerId = cContainer.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync events
    for (const xEvent of container.events) {
      xEvent.containerId = cContainer.id;
      await this.syncEventWithCloud(xEvent);
    }
    // sync infos
    for (const xInfo of container.infos) {
      xInfo.containerId = cContainer.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of container.links) {
      xLink.containerId = cContainer.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of container.notices) {
      xNotice.containerId = cContainer.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    // sync places
    for (const xPlace of container.places) {
      xPlace.containerId = cContainer.id;
      await this.syncPlaceWithCloud(xPlace);
    }
    // sync restos
    for (const xResto of container.restos) {
      xResto.containerId = cContainer.id;
      await this.syncRestoWithCloud(xResto);
    }
    // sync stays
    for (const xStay of container.stays) {
      xStay.containerId = cContainer.id;
      await this.syncStayWithCloud(xStay);
    }
    // sync transfers
    for (const xTransfer of container.transfers) {
      xTransfer.containerId = cContainer.id;
      await this.syncTransferWithCloud(xTransfer);
    }
    return cContainer;
  }
  private async syncDutyWithCloud(duty: Duty) {
    // create new duty
    const cDuty = cloneDuty(duty);
    if (duty.id < 0) {
      const newDuty = await this.addDuty(cDuty);
      if (!newDuty) { return null; }
      cDuty.id = newDuty.id;
    }
    if (duty.id > 0) {
      const result = await this.updateDuty(cDuty);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    return cDuty;
  }
  private async syncEventWithCloud(event: Event) {
    // create new event
    const cEvent = cloneEvent(event);
    if (event.id < 0) {
      const newEvent = await this.addEvent(cEvent);
      if (!newEvent) { return null; }
      cEvent.id = newEvent.id;
    }
    if (event.id > 0) {
      const result = await this.updateEvent(cEvent);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync duties
    for (const xDuty of event.duties) {
      xDuty.eventId = cEvent.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync infos
    for (const xInfo of event.infos) {
      xInfo.eventId = cEvent.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of event.links) {
      xLink.eventId = cEvent.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of event.notices) {
      xNotice.eventId = cEvent.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    return cEvent;
  }
  private async syncInfoWithCloud(info: Info) {
    // create new info
    const cInfo = cloneInfo(info);
    if (info.id < 0) {
      const newInfo = await this.addInfo(cInfo);
      if (!newInfo) { return null; }
      cInfo.id = newInfo.id;
    }
    if (info.id > 0) {
      const result = await this.updateInfo(cInfo);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    return cInfo;
  }
  private async syncLinkWithCloud(link: Link) {
    // create new info
    const cInfo = cloneLink(link);
    if (link.id < 0) {
      const newInfo = await this.addLink(cInfo);
      if (!newInfo) { return null; }
      cInfo.id = newInfo.id;
    }
    if (link.id > 0) {
      const result = await this.updateLink(cInfo);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    return cInfo;
  }
  private async syncNoticeWithCloud(notice: Notice) {
    // create new notice
    const cNotice = cloneNotice(notice);
    if (notice.id < 0) {
      const newDuty = await this.addNotice(cNotice);
      if (!newDuty) { return null; }
      cNotice.id = newDuty.id;
    }
    if (notice.id > 0) {
      const result = await this.updateNotice(cNotice);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    return cNotice;
  }
  private async syncPlaceWithCloud(place: Place) {
    // create new place
    const cPlace = clonePlace(place);
    if (place.id < 0) {
      const newPlace = await this.addPlace(cPlace);
      if (!newPlace) { return null; }
      cPlace.id = newPlace.id;
    }
    if (place.id > 0) {
      const result = await this.updatePlace(cPlace);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync containers
    for (const xContainer of place.containers) {
      xContainer.containerId = cPlace.id;
      await this.syncContainerWithCloud(xContainer);
    }
    // sync duties
    for (const xDuty of place.duties) {
      xDuty.placeId = cPlace.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync events
    for (const xEvent of place.events) {
      xEvent.placeId = cPlace.id;
      await this.syncEventWithCloud(xEvent);
    }
    // sync infos
    for (const xInfo of place.infos) {
      xInfo.placeId = cPlace.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of place.links) {
      xLink.placeId = cPlace.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of place.notices) {
      xNotice.placeId = cPlace.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    // sync places
    for (const xPlace of place.places) {
      xPlace.placeId = cPlace.id;
      await this.syncPlaceWithCloud(xPlace);
    }
    // sync restos
    for (const xResto of place.restos) {
      xResto.placeId = cPlace.id;
      await this.syncRestoWithCloud(xResto);
    }
    // sync stays
    for (const xStay of place.stays) {
      xStay.placeId = cPlace.id;
      await this.syncStayWithCloud(xStay);
    }
    return cPlace;
  }
  private async syncRestoWithCloud(resto: Resto) {
    // create new resto
    const cResto = cloneResto(resto);
    if (resto.id < 0) {
      const newResto = await this.addResto(cResto);
      if (!newResto) { return null; }
      cResto.id = newResto.id;
    }
    if (resto.id > 0) {
      const result = await this.updateResto(cResto);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync duties
    for (const xDuty of resto.duties) {
      xDuty.restoId = cResto.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync infos
    for (const xInfo of resto.infos) {
      xInfo.restoId = cResto.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of resto.links) {
      xLink.restoId = cResto.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of resto.notices) {
      xNotice.restoId = cResto.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    return cResto;
  }
  private async syncStayWithCloud(stay: Stay) {
    // create new stay
    const cStay = cloneStay(stay);
    if (stay.id < 0) {
      const newStay = await this.addStay(cStay);
      if (!newStay) { return null; }
      cStay.id = newStay.id;
    }
    if (stay.id > 0) {
      const result = await this.updateStay(cStay);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync duties
    for (const xDuty of stay.duties) {
      xDuty.stayId = cStay.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync infos
    for (const xInfo of stay.infos) {
      xInfo.stayId = cStay.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of stay.links) {
      xLink.stayId = cStay.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of stay.notices) {
      xNotice.stayId = cStay.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    return cStay;
  }
  private async syncTransferWithCloud(transfer: Transfer) {
    // create new transfer
    const cTransfer = cloneTransfer(transfer);
    if (transfer.id < 0) {
      const newEvent = await this.addTransfer(cTransfer);
      if (!newEvent) { return null; }
      cTransfer.id = newEvent.id;
    }
    if (transfer.id > 0) {
      const result = await this.updateTransfer(cTransfer);
      if (result !== EnumGlobalStatusCode.Success) { return null; }
    }
    // sync duties
    for (const xDuty of transfer.duties) {
      xDuty.eventId = cTransfer.id;
      await this.syncDutyWithCloud(xDuty);
    }
    // sync events
    for (const xEvent of transfer.events) {
      xEvent.transferId = cTransfer.id;
      await this.syncEventWithCloud(xEvent);
    }
    // sync infos
    for (const xInfo of transfer.infos) {
      xInfo.eventId = cTransfer.id;
      await this.syncInfoWithCloud(xInfo);
    }
    // sync links
    for (const xLink of transfer.links) {
      xLink.eventId = cTransfer.id;
      await this.syncLinkWithCloud(xLink);
    }
    // sync notices
    for (const xNotice of transfer.notices) {
      xNotice.eventId = cTransfer.id;
      await this.syncNoticeWithCloud(xNotice);
    }
    return cTransfer;
  }

}
